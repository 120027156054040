import React from 'react';
import Banner from './banner/Banner';
import Categories from './categories/Categories';
import PageContainer from '../../layouts/page-container/PageContainer';
import c from './home.module.scss';
import { categoryData } from '../category/data';
import bean from './home-banner-background.svg';

const Home = () => {
  return (
    <PageContainer>
      <div className={c.home}>
        <img src={bean} alt="" className={c.blueBean} aria-hidden="true" />
        <div className={c.homeContent}>
          <div className={c.bannerContainer}>
            <Banner />
          </div>
          <div className={c.categoryContainer}>
            <Categories data={categoryData} />
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default Home;
