import { Button, Icon, Tabs, Link } from '@monash/portal-react';
import { Faq, RenderAnimation } from '@monash/portal-frontend-common';
import React, { useContext, useState } from 'react';
import PageContainer from '../../layouts/page-container/PageContainer';
import SearchResultsCard from '../../ui/search-result-card/SearchResultsCard';
import c from './category.module.scss';
import { SearchContext } from '../../providers/search-provider/SearchProvider';
import HeroLotties from './HeroLotties';
import SearchError from '../search/SearchError';

const Category = ({ content, heroIndex }) => {
  const [categoryContent] = useState(content);

  // gets link entities from allLinks via ids
  const { allLinks, searchError } = useContext(SearchContext);
  const getEntityContent = (id) => {
    const linkIndex = allLinks.findIndex((entity) => entity.entityId === id);
    if (linkIndex === -1) {
      return null;
    } else {
      return allLinks[linkIndex].entityContent;
    }
  };

  // supports functionality to navigate tabs
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  // Content section per tab per section
  const TabPanelSection = ({ panelContent }) => {
    return panelContent?.map((item, i) => {
      const data = getEntityContent(item);

      if (!data) {
        return null;
      }

      return (
        <li key={i}>
          <SearchResultsCard
            entity={data}
            key={i}
            index={i}
            loading={allLinks.length === 0}
          />
        </li>
      );
    });
  };
  // Content section per tab
  const TabPanel = ({ tabPanel }) => {
    return (
      <div
        id={`tabpanel-${selectedTabIndex}`}
        role="tabpanel"
        aria-labelledby={`tab-${selectedTabIndex}`}
        tabIndex="0"
      >
        {Boolean(tabPanel[0].subName) &&
          tabPanel.map((item, i) => {
            return (
              <section key={i} className={c.linksSection}>
                <h2>{item.subName}</h2>
                <ul className={c.links}>
                  <TabPanelSection panelContent={item.contents} />
                </ul>
              </section>
            );
          })}
        <ul className={c.links}>
          <TabPanelSection panelContent={tabPanel} />
        </ul>
      </div>
    );
  };

  const heroLottie = HeroLotties(heroIndex);

  return (
    <PageContainer type="default">
      <Link to="/" className={c.backNavigation} tabIndex="-1">
        <Button size="small" variant="text" icon={<Icon.ChevronLeft />}>
          Back
        </Button>
      </Link>
      <div className={c.content}>
        <div className={c.banner}>
          <div className={c.header}>
            <h1 data-testid="category-title">{categoryContent.name}</h1>
            <p>{categoryContent.description}</p>
          </div>
          <RenderAnimation className={c.heroMedia} lottieFile={heroLottie} />
          {/* add new hero content to assets/lotties & update import in GetHeroLotties */}
        </div>

        {searchError && <SearchError />}
        {!searchError && (
          <div className={c.contentContainer}>
            {/* Popular links */}
            {/* eslint-disable-next-line jsx-a11y/prefer-tag-over-role */}
            <section
              role="contentinfo"
              aria-label="Popular links"
              className={c.contentSection}
            >
              <h2>Popular links</h2>
              <section className={c.links}>
                {content.popularEntIds && (
                  <TabPanelSection panelContent={content.popularEntIds} />
                )}
              </section>
            </section>

            {/* Tabbed links */}
            {/* eslint-disable-next-line jsx-a11y/prefer-tag-over-role */}
            <section
              role="contentinfo"
              aria-label="Content Tab Panel"
              className={c.contentSection}
            >
              <Tabs
                tabs={categoryContent.tabs.map((tabLabel) => tabLabel.name)}
                selected={selectedTabIndex}
                onChange={setSelectedTabIndex}
              />
              <TabPanel tabPanel={content.tabs[selectedTabIndex].contents} />
            </section>
          </div>
        )}

        {/* FAQs */}
        {categoryContent.faqIds && (
          <section className={c.contentSection}>
            <Faq title="Related FAQs" faqIds={categoryContent.faqIds} />
          </section>
        )}
      </div>
    </PageContainer>
  );
};

export default Category;
