import * as Sentry from '@sentry/react';

const initMonitoring = (env) => {
  // SENTRY
  Sentry.init({
    dsn: 'https://1dc122537de8630d04b83b7bd3e5a517@o4506034322079744.ingest.sentry.io/4506058219651072',
    environment: env,
    release: process.env.REACT_APP_GIT_SHA,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
        maskAllInputs: true,
      }),
    ],
    ignoreErrors: [
      'Firebase',
      'Firestore',
      '/user/preferences/avatar',
      'Load failed',
    ],
    // Performance Monitoring
    tracesSampleRate: env === 'prod' ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.001, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export default initMonitoring;
