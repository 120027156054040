import campTrans from '../../../assets/lotties/campus_transport.json';
import careerOpps from '../../../assets/lotties/career_opportunities.json';
import crsEnrl from '../../../assets/lotties/course_enrolment.json';
import feesSchol from '../../../assets/lotties/fees_scholarships.json';
import hlpSup from '../../../assets/lotties/help_support.json';
import lrnRes from '../../../assets/lotties/learning_research.json';
import res from '../../../assets/lotties/research.json';

// Note: list items order below must NOT be changed
const ALL_HERO_LOTTIES = [
  lrnRes,
  crsEnrl,
  careerOpps,
  campTrans,
  feesSchol,
  hlpSup,
  res,
];

const HeroLotties = (index) => {
  return ALL_HERO_LOTTIES[index];
};

export default HeroLotties;
