import React from 'react';
import Category from '../Category';
import c from './category-group.module.scss';

const CategoryGroup = ({ type, categories }) => {
  return (
    <div className={c.categoryGroup}>
      <h3>{`Browse by ${type}`}</h3>
      <ul>
        {categories?.map((item, i) => (
          <Category
            name={item.name}
            icon={item.icon}
            to={item.path}
            key={item.name + i}
          />
        ))}
      </ul>
    </div>
  );
};

export default CategoryGroup;
