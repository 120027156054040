import { Link } from '@monash/portal-react';
import React from 'react';
import c from './category.module.scss';

const Category = ({ name, icon, to }) => {
  return (
    <Link to={to}>
      <li className={c.categoryCard}>
        <div className={c.category}>
          <div className={c.icon}>{icon}</div>
          <p>{name}</p>
        </div>
      </li>
    </Link>
  );
};

export default Category;
