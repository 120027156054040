import React from 'react';
import { Icon } from '@monash/portal-react';

export const categoryData = [
  {
    name: 'Learning',
    type: 'topic',
    icon: <Icon.Pencil size={24} />,
    path: '/learning',
    description:
      'Find all the tools you need to get organised and complete your studies',
    popularEntIds: [
      'hGM0ZyPrTOhZxlimx44f',
      'pSbcfaAVKKqQIXH4cuaE',
      'AyIym3aj0oFMYmhnC5lD',
      'T3JkZb2xuk8YXBS7kFzA',
      'enBU6vttybNxhPRPnQMf',
      'a64OxIqSIVq88mjWPHgg',
      'L1DnxWyQa0FMPCcW1G7g',
      'Ne0LZ9pZS1Hlf93vad8e',
    ],
    tabs: [
      {
        name: 'Learning',
        contents: [
          'hGM0ZyPrTOhZxlimx44f',
          'enBU6vttybNxhPRPnQMf',
          'jU3UyQY2ZjoKqzJftqLZ',
          'L1DnxWyQa0FMPCcW1G7g',
          'K9gRU4pkoCCyxYOlRUaE',
          'pylaegREDBKDOrfda8MU',
          'oquUGQi574lIX0yRRP8H',
          'Qst0DGXELgkJc16GRBj7',
        ],
      },
      {
        name: 'Research',
        contents: [
          '7RvvjHcFvfrxwRawcjDN',
          'ptaXa5qeG9awRUZW7s0G',
          'xEmqfsUCsshSr08UFWRa',
          'rCgeYwil1JRYGF2qfjGd',
          '3GlW2W82V7UqAo35uyJb',
          'Tgv1Le7YGepv3qxGxJ8r',
          'gZQ2QURHdnocTa5cJbCw',
          'PkP49XoOB8Zh9co2vMBQ',
        ],
      },
      {
        name: 'Library',
        contents: [
          'pSbcfaAVKKqQIXH4cuaE',
          'a64OxIqSIVq88mjWPHgg',
          'NfY0DDXtr5RlZ4TDhwue',
          '0J0XOvZ7QAdmJDKi5wyl',
          'WhfC5MZhA871N8cZtr5f',
          'Q5lJCbousYTTgEqQu6BL',
          'Ne0LZ9pZS1Hlf93vad8e',
          '5LY1e4CoQxlXAHA7KTdK',
          '8BviYbGXONZMAB1CoTs5',
        ],
      },
      {
        name: 'Printing',
        contents: [
          'Mgr5d72EUybDKgSifhkx',
          'xSjrBShVFRme0w8NcnIG',
          'uUbiLLDX9IpewntGkJrH',
          'lFyHo9x3Kj7LwYLQ1cdo',
        ],
      },
    ],
    faqIds: ['FAQ-2402', 'FAQ-2858', 'FAQ-2793', 'FAQ-2618', 'FAQ-2537'],
  },
  {
    name: 'Course and enrolment',
    type: 'topic',
    icon: <Icon.NoteBookAndPen size={24} />,
    path: '/course-enrolment',
    description:
      'Get all the information you need to enrol or re-enrol and access forms and resources for your course',
    popularEntIds: [
      'tx0imobbDAe3GeHRZvgA',
      'S5PYDEyx0ZOGWN6fG0rh',
      'QOAzaXePS6S9zyGe78Dh',
      'd77mcLubtJJ7RKoqUHWU',
    ],
    tabs: [
      {
        name: 'Course and unit',
        contents: [
          'tx0imobbDAe3GeHRZvgA',
          'zA5QFx578ZEgp9DzI0TV',
          'S5PYDEyx0ZOGWN6fG0rh',
          'ihiWr4PISt6NzQJOJUNI',
          'NaJwBfVlXtqJ2mo9rUry',
          'enBU6vttybNxhPRPnQMf',
          'jU3UyQY2ZjoKqzJftqLZ',
          'xrWsbSj08kbvYiFupD01',
        ],
      },
      {
        name: 'Enrolment / re-enrolment',
        contents: [
          'L1DnxWyQa0FMPCcW1G7g',
          'd77mcLubtJJ7RKoqUHWU',
          'J54ZfhL6D55PvpFiSoK7',
          'aQl5iZ8GchxZdTJ89VzG',
          'Zee9S1jXeOZmIGxojjFn',
          '2THDueyOTdJeiyDJ65dp',
          't8SIF87D7uzrv354yGva',
          '2GXZrjIXqylZxrKXfurW',
        ],
      },
      {
        name: 'Graduation',
        contents: [
          'mLI8uC2WTT7m85QLbMlj',
          'Pb6m7Kr9vGyFwbpd29IL',
          'oBqJ6IXZtH4hSAxqZJJy',
          'rI8RpuGEJ7TqwkPKhTMJ',
          'dKrgUXMK1uWHeFX7Fp6y',
          'e3bOkTZnTMTCDRqKuPxS',
          'k82wGPYI1LiZchbcdOQh',
          'ZOj9nm12h0t427EeMxzQ',
        ],
      },
      {
        name: 'Malaysia',
        contents: ['NNoZY3IAoOkjxmYkVF3i'],
      },
    ],
    faqIds: [
      'FAQ-2387',
      'FAQ-2840',
      'FAQ-2702',
      'FAQ-2935',
      'FAQ-2827',
      'FAQ-2547',
      'FAQ-2719',
      'FAQ-2493',
      'FAQ-2575',
    ],
  },
  {
    name: 'Career and opportunities',
    type: 'topic',
    icon: <Icon.Book size={24} />,
    path: '/career-opportunities',
    description:
      'Search through the many career opportunities available through Monash, including jobs you can apply for right now and upskilling workshops to give you that extra edge',
    popularEntIds: [
      'JXfeadxYy6z6k0eyXNQQ',
      'sOtpjsbDxptjiijvS1eY',
      'RXJ7gi21KWRahJ99nFUj',
      '4jYSHOXnh7b5reobv852',
    ],
    tabs: [
      {
        name: 'Career preparation',
        contents: [
          'VPdJfo67WG4kBYuFnsF4',
          '2p6l7LM2byCvzlzc2b5g',
          'HnD1GoMx9rAOCsl5BdJi',
          'RAEsud3FRPWT96ykjK05',
          'wUlsYDsxcaRtyiiZt0gt',
          'vGIYTjFRmVY0vhAGFkD9',
          '4jYSHOXnh7b5reobv852',
          'UrZXdmd8ROmIJwwlvW40',
        ],
      },
      {
        name: 'Future career',
        contents: [
          'cnM7SMhklBoH0NQRU4tk',
          'RXJ7gi21KWRahJ99nFUj',
          'sOtpjsbDxptjiijvS1eY',
          'JXfeadxYy6z6k0eyXNQQ',
        ],
      },
      {
        name: 'Work experience and volunteering',
        contents: [
          'KHwOraX5GyGpemmKpcA2',
          'JJ7AxxorN6hC2QoKImqG',
          'JV7DXD77nypAyxtmMu4P',
          'ySNs5saZ93BP3fSWjIoq',
        ],
      },
      {
        name: 'Professional development',
        contents: [
          'LpJSgTPTrgZsoK0b4GeY',
          '15PTPZdvpiynFCAMtwRk',
          '2n9i8dDXBhRq3DF7ujbh',
          'LKxmIzAkPwE39y5bWuXz',
        ],
      },
      {
        name: 'Malaysia',
        contents: ['ExJ1o1WDRvXZ7AFp1tfN', 'fqReoovURjO8VOGEqkZ3'],
      },
    ],
    faqIds: ['FAQ-2272'],
  },
  {
    name: 'Campus life and transport',
    type: 'topic',
    icon: <Icon.BuildingCommunity size={24} />,
    path: '/campus-life-transport',
    description:
      'Discover the spaces, events and services you can access on campus: from food and retail to student clubs and societies',
    popularEntIds: [
      'ochFwzNssswqKKOCcV1r',
      'EieiSeTHUpZZ6NuYj3EZ',
      'ljVakDZ9vgkwQO811R5e',
      'rozLN83T6xahGPk9xxXE',
    ],
    tabs: [
      {
        name: 'Clayton',
        contents: [
          {
            subName: 'Campus life',
            contents: [
              '8zuPwf1LYbrF6dE2gNXF',
              'mNXlqalvdBPIKgeelCCI',
              '1kRvU9UpNBF1Hat5pjfx',
              'ljVakDZ9vgkwQO811R5e',
              'rozLN83T6xahGPk9xxXE',
            ],
          },
          {
            subName: 'Parking and transport',
            contents: [
              'NO4p4HaM9L5jrZmFhjVm',
              'PoUeAqsBE7wXuhRPJIJp',
              '2xIlLWQJwIvE94ttYVfN',
            ],
          },
          {
            subName: 'News and events',
            contents: [
              'ochFwzNssswqKKOCcV1r',
              'D7u8dF0mo5eWOigvSc4A',
              '75Lm4fhOkqDSP7hhI6Ep',
              'mANQWMVhSEMpLft6KQlO',
            ],
          },
          {
            subName: 'Student discounts and concessions',
            contents: [
              'i09yh2kKs6FQs2oa1763',
              'qaVZ1IAJM59JqXcE90gk',
              'wTyXWCpEwV4SPB4shb09',
            ],
          },
          {
            subName: 'Accommodation',
            contents: ['nEttoDqZkwtxqZuKx5ji', 'sxf7bBke5j9DTjvVO1O7'],
          },
        ],
      },
      {
        name: 'Caulfield',
        contents: [
          {
            subName: 'Campus life',
            contents: [
              'fEpMi1EbpQgU9cB5ObWo',
              'kXxsAvgyQbXXBC5memiZ',
              '22ndVCj7sgo27acTIWkq',
              'ljVakDZ9vgkwQO811R5e',
              'rozLN83T6xahGPk9xxXE',
            ],
          },
          {
            subName: 'Parking and transport',
            contents: [
              'NO4p4HaM9L5jrZmFhjVm',
              'j2T8uzF3BEFt0UVH0Xv9',
              '8odpcT8fQqT7PtTl7iJK',
            ],
          },
          {
            subName: 'News and events',
            contents: [
              'ochFwzNssswqKKOCcV1r',
              'D7u8dF0mo5eWOigvSc4A',
              '75Lm4fhOkqDSP7hhI6Ep',
              'mANQWMVhSEMpLft6KQlO',
            ],
          },
          {
            subName: 'Student discounts and concessions',
            contents: [
              'i09yh2kKs6FQs2oa1763',
              'qaVZ1IAJM59JqXcE90gk',
              'wTyXWCpEwV4SPB4shb09',
            ],
          },
          {
            subName: 'Accommodation',
            contents: ['nEttoDqZkwtxqZuKx5ji', 'sxf7bBke5j9DTjvVO1O7'],
          },
        ],
      },
      {
        name: 'Parkville',
        contents: [
          {
            subName: 'Campus life',
            contents: [
              'Z0118TBeXERJUiax9kMo',
              'tZU2QFK9zCD7rPr9l0T0',
              'ljVakDZ9vgkwQO811R5e',
              'rozLN83T6xahGPk9xxXE',
              'ZYauYbhWqwqTg6EXJSJY',
            ],
          },
          {
            subName: 'Parking and transport',
            contents: ['NO4p4HaM9L5jrZmFhjVm', 'VC1Dg5DVXIc04ObHvIGP'],
          },
          {
            subName: 'News and events',
            contents: [
              'ochFwzNssswqKKOCcV1r',
              'D7u8dF0mo5eWOigvSc4A',
              '75Lm4fhOkqDSP7hhI6Ep',
              'mANQWMVhSEMpLft6KQlO',
            ],
          },
          {
            subName: 'Student discounts and concessions',
            contents: [
              'i09yh2kKs6FQs2oa1763',
              'qaVZ1IAJM59JqXcE90gk',
              'wTyXWCpEwV4SPB4shb09',
            ],
          },
          {
            subName: 'Accommodation',
            contents: ['nEttoDqZkwtxqZuKx5ji', 'sxf7bBke5j9DTjvVO1O7'],
          },
        ],
      },
      {
        name: 'Peninsula',
        contents: [
          {
            subName: 'Campus life',
            contents: [
              'OOKCI59VJJ1Hmsvp3BcH',
              'N1piw32j2IYtNr99Gxh7',
              'FGhib8usFPoNulCIAZzh',
              'ljVakDZ9vgkwQO811R5e',
              'rozLN83T6xahGPk9xxXE',
            ],
          },
          {
            subName: 'Parking and transport',
            contents: ['NO4p4HaM9L5jrZmFhjVm', 'dLhvbnjegsyOjhEyCzsb'],
          },
          {
            subName: 'News and events',
            contents: [
              'ochFwzNssswqKKOCcV1r',
              'D7u8dF0mo5eWOigvSc4A',
              '75Lm4fhOkqDSP7hhI6Ep',
              'mANQWMVhSEMpLft6KQlO',
            ],
          },
          {
            subName: 'Student discounts and concessions',
            contents: [
              'i09yh2kKs6FQs2oa1763',
              'qaVZ1IAJM59JqXcE90gk',
              'wTyXWCpEwV4SPB4shb09',
            ],
          },
          {
            subName: 'Accommodation',
            contents: ['nEttoDqZkwtxqZuKx5ji', 'sxf7bBke5j9DTjvVO1O7'],
          },
        ],
      },
      {
        name: 'Malaysia',
        contents: [
          {
            subName: 'Campus life',
            contents: ['Vq5d4gbrUIH0ZxiOWFRd', 'MYU9V3idEbW6cQf0XEE0'],
          },
          {
            subName: 'Parking and transport',
            contents: ['HiOx4PI3DKC1V8CJ8DYx'],
          },
        ],
      },
    ],
    faqIds: ['FAQ-2575', 'FAQ-2735', 'FAQ-3523', 'FAQ-2917'],
  },
  {
    name: 'Fees and scholarships',
    type: 'topic',
    icon: <Icon.CurrencyDollar size={24} />,
    path: '/fees-scholarships',
    description:
      'Find all the fees, budgeting and financial support resources you need',

    popularEntIds: [
      'rRIa1K8NpwcM4Q59jKiT',
      'TgAXFcHQnF6sRwGgpla4',
      'TrCVxC66Abs9XsJbDW3f',
      'qi2tHaCSPwLC5CEVX2z6',
    ],
    tabs: [
      {
        name: 'Domestic',
        contents: [
          {
            subName: 'Fees',
            contents: [
              'YDgCFnC4yi5QcK2JI2MS',
              'TgAXFcHQnF6sRwGgpla4',
              'K6C6x5tpwiBZxdKgNS0T',
              'QhweTJJsXa5CHxTxR82g',
            ],
          },
          {
            subName: 'Scholarships',
            contents: [
              'LvoHH6MDzGjSX9jMg7lU',
              'aOSE2PZ1JgPJto59o70Y',
              'rRIa1K8NpwcM4Q59jKiT',
              'xuHDy4tEbIOahetPlryP',
            ],
          },
          {
            subName: 'Support',
            contents: [
              'LWfvSi5kxynIlfe7GdKs',
              'HLw8HZF7IbKjrzBDBGoh',
              'qi2tHaCSPwLC5CEVX2z6',
              'okKHESKR1UVjdKIWPPDO',
              '8jsJdpiT3v0vGgQgXQRS',
              'xOEqMbVZgLtRGSLPZfV6',
              'NuG8AzfOlc0YF9KLnMDm',
              'ssULcTxLnkZ0uAUZaJak',
            ],
          },
        ],
      },
      {
        name: 'International',
        contents: [
          {
            subName: 'Fees',
            contents: [
              'YDgCFnC4yi5QcK2JI2MS',
              'TgAXFcHQnF6sRwGgpla4',
              'K6C6x5tpwiBZxdKgNS0T',
              'bOvnDDmJyBqFgbLgIzOc',
            ],
          },
          {
            subName: 'Scholarships',
            contents: [
              'LvoHH6MDzGjSX9jMg7lU',
              'aOSE2PZ1JgPJto59o70Y',
              'rRIa1K8NpwcM4Q59jKiT',
              'xuHDy4tEbIOahetPlryP',
            ],
          },
          {
            subName: 'Support',
            contents: [
              'HLw8HZF7IbKjrzBDBGoh',
              'ssULcTxLnkZ0uAUZaJak',
              'okKHESKR1UVjdKIWPPDO',
              'xOEqMbVZgLtRGSLPZfV6',
              'NuG8AzfOlc0YF9KLnMDm',
              '8jsJdpiT3v0vGgQgXQRS',
            ],
          },
        ],
      },
      {
        name: 'Malaysia',
        contents: [
          {
            subName: 'Fees',
            contents: [
              'zj98NBZHIPbdDo11nDRr',
              'um8rn7dlFkHVI1oumHMR',
              'Ucsmj1fAaIzuqLPYOe91',
            ],
          },
          {
            subName: 'Scholarships',
            contents: ['xuHDy4tEbIOahetPlryP', 'rRIa1K8NpwcM4Q59jKiT'],
          },
          {
            subName: 'Support',
            contents: ['qVxnsibvs4HldKN8x44t', 'aXzKa4DgIL6P8bxzJ0mV'],
          },
        ],
      },
    ],
    faqIds: [
      'FAQ-2601',
      'FAQ-2831',
      'FAQ-2473',
      'FAQ-2357',
      'FAQ-2536',
      'FAQ-2741',
      'FAQ-2450',
      'FAQ-2334',
    ],
  },
  {
    name: 'Help and support',
    type: 'topic',
    icon: <Icon.Rocket size={24} />,
    path: '/help-support',
    description: 'Browse all the support services we offer here at Monash',
    popularEntIds: [
      'Qst0DGXELgkJc16GRBj7',
      'ns1CIkMd76eKFMqO4Nsp',
      'nzvOFaCGAH3dNo6Oa10Y',
      '2LRI7zsizZjvjm6SPrm6',
    ],
    tabs: [
      {
        name: 'Help Services',
        contents: [
          'IM5sdGCycg7Q2NdoivT4',
          'ns1CIkMd76eKFMqO4Nsp',
          's5m0arvwjDPz9jEkvKqv',
          'VPdJfo67WG4kBYuFnsF4',
          'sY3qswFqTMv7jrWuplMG',
          'bbtjAQrlr2KRvSKp7KLa',
          'U7O98guK6VZs5yYG86IJ',
          'F9NhV7UWfst6Tu6PwkOR',
          'UaVBdBzCJkLnlS8yy6og',
          'r2BjZLEWNZybkcLmX8Xk',
          '3WlqMskaqG7xnJQtm50m',
          'Z8MHsht6e0BCkoPum90j',
          'kCSR9WqUd2FcmRK8oa3U',
          'wZtZrkSRIKjKdVBeKQk2',
          '16IU3mQOsSHHP5dMEvsD',
          'qsBk2UUhrVCE0lIEgUmZ',
          'WnSeLWleYpc5y5wOnROh',
        ],
      },
      {
        name: 'Health and wellbeing',
        contents: [
          'nzvOFaCGAH3dNo6Oa10Y',
          'iTN4w6pZERKexg8pswxU',
          '2LRI7zsizZjvjm6SPrm6',
        ],
      },
      {
        name: 'Financial assistance',
        contents: [
          'HLw8HZF7IbKjrzBDBGoh',
          'xOEqMbVZgLtRGSLPZfV6',
          'NuG8AzfOlc0YF9KLnMDm',
          '8jsJdpiT3v0vGgQgXQRS',
        ],
      },
      {
        name: 'Study and assessments',
        contents: [
          'Qst0DGXELgkJc16GRBj7',
          'T3JkZb2xuk8YXBS7kFzA',
          '07xZ51Hj50h0ztBkfofP',
          'uPbwuhcFyscFNmfRCiue',
        ],
      },
      {
        name: 'New and international students',
        contents: [
          'ae2PVSd3NvZ3SuUNN4dZ',
          'nEttoDqZkwtxqZuKx5ji',
          'U7O98guK6VZs5yYG86IJ',
          '0XhjPDk3neVb9rrGbNgm',
        ],
      },
    ],
  },
  {
    name: 'Research',
    type: 'group',
    icon: <Icon.Script size={24} />,
    path: '/research',
    description: 'Find all the tools you need for your research',
    popularEntIds: ['atOd3WpiLpVz0unygjYE', '9PczevjjhMSeQzLxRPZ5'],
    tabs: [
      {
        name: 'Australia',
        contents: [
          {
            subName: 'Access research tools',
            contents: [
              '3GlW2W82V7UqAo35uyJb',
              'atOd3WpiLpVz0unygjYE',
              'xEmqfsUCsshSr08UFWRa',
              '5MdkcYzmmkw5gpSEaHJj',
              'YbfurHTjqjNq8KWcIOSU',
              '9PczevjjhMSeQzLxRPZ5',
              '7RvvjHcFvfrxwRawcjDN',
              'wXs2QCnIwTJDdYBFN3mR',
              'LC9TCWa2ADF0dj8EzfUT',
              'wF5QBJr5fNNJTAFqmV1O',
              'Tgv1Le7YGepv3qxGxJ8r',
            ],
          },
          {
            subName: 'Use Services',
            contents: [
              'kywHIsmlbzjaJHJL52fw',
              'HXXcglEGCmzy0ysesQg7',
              'FBhH2Y3se5pMu8Js5MRM',
              'z15Onhf1iSLDdEDSDLtZ',
              'AyIym3aj0oFMYmhnC5lD',
              'tCue0yD1RaD3WGvxa51M',
              'fWRdYpmDqhOT3fqb0uIf',
              'rCgeYwil1JRYGF2qfjGd',
              'znsMWeC5IeTOxP3P6kIU',
              '6qqYH8vDlxFCX4kuksnf',
            ],
          },
          {
            subName: 'Managing Data',
            contents: [
              'w7TSMIF7hp3MKTx1wSZT',
              'ahVP8lM4Ov7ANfbZW5h1',
              '8Gskeb81Akw53pVGvlyZ',
            ],
          },
        ],
      },
      {
        name: 'Malaysia',
        contents: [
          {
            subName: 'Access research tools',
            contents: [
              '3GlW2W82V7UqAo35uyJb',
              'atOd3WpiLpVz0unygjYE',
              'xEmqfsUCsshSr08UFWRa',
              '9PczevjjhMSeQzLxRPZ5',
              '7RvvjHcFvfrxwRawcjDN',
            ],
          },
          {
            subName: 'Use Services',
            contents: ['tCue0yD1RaD3WGvxa51M', 'FBhH2Y3se5pMu8Js5MRM'],
          },
        ],
      },
    ],
  },
];
